@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}
.contact-icon {
  background-color: #4abddc;
  color: white;
  display: block;
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-icon > svg {
  font-size: 1.5rem;
}
.read-more-less {
  display: inline;
  border: none;
  background-color: transparent;
  color: greenyellow;
  padding: 0;
  margin: 0;
}
